body{
  background-color: #cac8bc;
  font-family: 'Rubik', sans-serif;
}
.container{
  max-width: 600px;
}
.btn{
  margin: 5px;
}
.ballsContainer {
  padding: 0px 5px;
}
.ballImg{
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ballImg img{
  max-width: 61px;
  max-height: 61px;
}
.overlayImage {
  position: absolute;
  max-width: 61px;
  max-height: 61px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
.bgGreen {
  background-color: #71b081;
}

.bgGray {
  background-color: gray;
}

.bgBlack {
  background-color: black;
}
.centerTxt{
  text-align: center;
}
.icon{
  max-width: 15px;
  max-height: 15px;
  margin-right:2px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.playerContainer{
  background-color: #8f9ba3; 
  border: none;
  border-radius: 10px;
}
.playerName{
  font-weight: bold;
  font-size: 1.5rem;
  color: #dfdccd;
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.toWin{
  color: #dfdccd;
  font-weight: bold;
  font-size: .8rem;
}
.scoreBoard h1{
  font-family: 'Belanosima', sans-serif;
  color: #dfdccd;
  margin: 0px;
  padding: 0px;
}
.rackNumber h2{
  color: #505049;
  font-family: 'Belanosima', sans-serif;
}
.skillLevel{
  color: #dfdccd;
  font-weight: bold;
  font-size: .9rem;
}
#logoWrap img{
  width: 100%;
}
.bold{
  font-weight: bold;
  font-family: 'Belanosima', sans-serif;
}

